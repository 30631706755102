import deskImgOnePng from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_desk.png"
import deskImgOnePng2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_desk@2x.png"
import deskImgOneWebp from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_desk.webp"
import deskImgOneWebp2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_desk@2x.webp"
import mobImgOnePng from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_mob.png"
import mobImgOnePng2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_mob@2x.png"
import mobImgOneWebp from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_mob.webp"
import mobImgOneWebp2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-01_mob@2x.webp"

import deskImgThreePng from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_desk.png"
import deskImgThreePng2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_desk@2x.png"
import deskImgThreeWebp from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_desk.webp"
import deskImgThreeWebp2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_desk@2x.webp"
import mobImgThreePng from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_mob.png"
import mobImgThreePng2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_mob@2x.png"
import mobImgThreeWebp from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_mob.webp"
import mobImgThreeWebp2x from "../../../../assets/images/raster/pages/cn/instructions/SectionOne/cn-instructions-03_mob@2x.webp"

const PG_CI_SECTION_ONE_CONTENT = {
  listPicturesOne: {
    deskImgPng: deskImgOnePng,
    deskImgPng2x: deskImgOnePng2x,
    deskImgWebp: deskImgOneWebp,
    deskImgWebp2x: deskImgOneWebp2x,
    mobImgPng: mobImgOnePng,
    mobImgPng2x: mobImgOnePng2x,
    mobImgWebp: mobImgOneWebp,
    mobImgWebp2x: mobImgOneWebp2x,
  },
  listPicturesThree: {
    deskImgPng: deskImgThreePng,
    deskImgPng2x: deskImgThreePng2x,
    deskImgWebp: deskImgThreeWebp,
    deskImgWebp2x: deskImgThreeWebp2x,
    mobImgPng: mobImgThreePng,
    mobImgPng2x: mobImgThreePng2x,
    mobImgWebp: mobImgThreeWebp,
    mobImgWebp2x: mobImgThreeWebp2x,
  },
  urlVideoPRO:
    "https://player.bilibili.com/player.html?aid=980695877&bvid=BV1y44y1379E&cid=576726983&page=1",
  urlVideoPD:
    "https://player.bilibili.com/player.html?aid=810661225&bvid=BV1t34y1v7m4&cid=576715489&page=1",
}
export default PG_CI_SECTION_ONE_CONTENT
