import React from "react";
import SvgIcon from "../../../../assets/images/vector/pages/cn/instructions/openWithVpnIcon.svg";
import SvgPlay from "../../../../assets/images/vector/pages/cn/instructions/play-btt.svg";
import TrackedLink from "../../../Analytics/TrackedLink";
import SectionOneScreenshot from "./SectionOneScreenshot";
import PG_CI_SECTION_ONE_CONTENT from "./index.content";
import "./index.scss";

const SectionOne = ({ setUrlYoutubeModal }) => {
  const { listPicturesOne, listPicturesThree, urlVideoPRO, urlVideoPD } =
    PG_CI_SECTION_ONE_CONTENT;

  return (
    <section className="PgCI-SectionOne">
      <div className="PgCI-SectionOne__wrap">
        <div className="PgCI-SectionOne__container">
          <h1 className="PgCI-SectionOne__title">
            有关安装和使用AMZ侦探工具的说明
          </h1>
          <div className="PgCI-SectionOne-contentOne">
            <TrackedLink
              category="CNInstructionLanding"
              action="如何在使用VPN 的情况下安装AMZ侦探 PRO 插件"
              class="PgCI-SectionOne-link PgCI-SectionOne-link__one"
              path="/cn/pro-vpn"
            >
              <SectionOneScreenshot
                listPictures={listPicturesOne}
                classes="PgCI-SectionOne-screenshot"
                icon={SvgIcon}
              />
              <p className="PgCI-SectionOne-contentOne__title">
                <span>01.</span>如何在使用VPN 的情况下安装AMZ侦探 PRO 插件
              </p>
            </TrackedLink>
            <TrackedLink
              category="CNInstructionLanding"
              action="如何在不使用VPN的情况下安装或更新AMZ侦探 PRO插件"
              class="PgCI-SectionOne-link PgCI-SectionOne-link__two"
              path="/cn/withoutvpn"
            >
              <SectionOneScreenshot
                listPictures={listPicturesOne}
                classes="PgCI-SectionOne-screenshot"
              />
              <p className="PgCI-SectionOne-contentOne__title">
                <span>02.</span>如何在不使用VPN的情况下安装或更新AMZ侦探 PRO插件
              </p>
            </TrackedLink>
            <TrackedLink
              category="CNInstructionLanding"
              action="如何通过VPN使用AMZ侦探工具"
              class="PgCI-SectionOne-link PgCI-SectionOne-link__three"
              path="/cn/wa-vpn"
            >
              <SectionOneScreenshot
                listPictures={listPicturesThree}
                classes="PgCI-SectionOne-screenshot"
                icon={SvgIcon}
              />
              <p className="PgCI-SectionOne-contentOne__title">
                <span>03.</span>如何通过VPN使用AMZ侦探工具
              </p>
            </TrackedLink>
          </div>
          <h2 className="PgCI-SectionOne__subTitle">
            关于AMZScout工具的培训资料
          </h2>
          <div className="PgCI-SectionOne-contentTwo">
            <div
              className="PgCI-SectionOne-link PgCI-SectionOne-link__four"
              onClick={() => setUrlYoutubeModal(urlVideoPRO)}
            >
              <SectionOneScreenshot
                listPictures={listPicturesOne}
                classes="PgCI-SectionOne-screenshot"
                icon={SvgPlay}
                blackout
              />
              <p className="PgCI-SectionOne-contentTwo__title">PRO 扩展</p>
              <p className="PgCI-SectionOne-contentTwo__text">
                找到并验证盈利产品，看看它们是否有上升或下降的趋势。检查竞争水平，获取采购选项，提高您的listing质量。
              </p>
            </div>
            <div
              className="PgCI-SectionOne-link PgCI-SectionOne-link__five"
              onClick={() => setUrlYoutubeModal(urlVideoPD)}
            >
              <SectionOneScreenshot
                listPictures={listPicturesThree}
                classes="PgCI-SectionOne-screenshot"
                icon={SvgPlay}
                blackout
              />
              <p className="PgCI-SectionOne-contentTwo__title">产品数据库</p>
              <p className="PgCI-SectionOne-contentTwo__text">
                产生有利润的产品创意，从有利润的利基市场中选择最佳产品，通过每日追踪检查产品性能
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
