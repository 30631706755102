import React from "react";
import PropTypes from "prop-types";

const SectionOneScreenshot = (props) => {
  const { listPictures, classes, icon, blackout } = props;

  const {
    deskImgWebp,
    deskImgWebp2x,
    deskImgPng,
    deskImgPng2x,
    mobImgWebp,
    mobImgWebp2x,
    mobImgPng,
    mobImgPng2x,
  } = listPictures;

  return (
    <div className={classes}>
      {!!blackout && <div className={`${classes}__blackout`} />}
      {!!icon && (
        <img
          className={`${classes}__icon`}
          src={icon}
          alt="img"
          loading="lazy"
        />
      )}
      <picture>
        <source
          type="image/webp"
          media="(min-width: 768px)"
          srcSet={`${deskImgWebp} 1x, ${deskImgWebp2x} 2x`}
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${deskImgPng} 1x, ${deskImgPng2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${mobImgWebp} 1x, ${mobImgWebp2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${mobImgPng} 1x, ${mobImgPng2x} 2x`}
        />
        <img
          src={deskImgPng}
          alt="img"
          loading="lazy"
          className={`${classes}__img`}
        />
      </picture>
    </div>
  );
};

SectionOneScreenshot.propTypes = {
  listPictures: PropTypes.object,
  classes: PropTypes.string,
  icon: PropTypes.string,
};

export default SectionOneScreenshot;
