import React, { useEffect, useState } from "react";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import Layout from "../../layouts/LayoutCn";
import Seo from "../../components/Seo";
import SectionOne from "../../components/cn/instructions/SectionOne";

const PgCI = () => {
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  useEffect(() => {
    const handleKeyEsc = (e) => e.keyCode === 27 && closeYoutubeModal();
    document.addEventListener("keydown", handleKeyEsc);

    return () => {
      document.removeEventListener("keydown", handleKeyEsc);
    };
  }, []);

  return (
    <Layout
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      bgHeader="#fff"
    >
      <Seo
        title="有关安装和使用AMZ侦探工具的说明"
        description="有关安装和使用AMZ侦探工具的说明"
        page="cn/instructions"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <SectionOne setUrlYoutubeModal={setUrlYoutubeModal} />
    </Layout>
  );
};

export default PgCI;
